import React, {useContext, useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {empty, req_cust_approve, req_cust_insert, req_module_setting, req_salesman, req_stocktemplate} from '../../Helper/Constants';
import AppContext from '../../Services/AppContext';
import {useSnackbar} from 'notistack';
import Typography from '@material-ui/core/Typography';
import {useCommonStyles} from '../../Styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import _ from 'lodash';
import VirtualListCB from '../../Components/VirtualListCB';
import {useMediaQuery, useTheme} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {de, getSetting} from '../../Helper/Helper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	dlg: {
		minWidth: 300,
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	},
	viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
	btn:{
		width:'100%',
		height:50,
		textAlign:'center',
		fontSize:12
	},
	error:{
		color: `${red[500]} !important`,
		borderColor: `${red[400]} !important`,
		'&:hover': {
			borderColor: `${red[500]} !important`,
		}
	}
}));

const outlets = [
	{
		name: "supermarket"
	},
	{
		name: "hypermarket"
	},
	{
		name: "wholesales"
	}
];

const options = [{'name':'Option 1','value':'Option 1'}, {'name':'Option 2','value':'Option 2'}];

export default function AddCustomer({open, onClose, language, salesperson = '', currentCustomer = null, custCategoryList = [], custChainList = [], custTypeList = [], custAreaList = [], branchesList = [], currentInput = null, onCancel}) {
	const commonStyle = useCommonStyles();
	const classes = useStyles();
	const [gState] = useContext(AppContext);
	const [axiosConfig, setAxiosConfig] = useState({});

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [customer, setCustomer] = useState(currentCustomer);
	const [custCode, setCustCode] = useState(empty);
	const [custName, setCustName] = useState(empty);
	const [custAddress1, setCustAddress1] = useState(empty);
	const [custAddress2, setCustAddress2] = useState(empty);
	const [state, setState] = useState(empty);
	const [city, setCity] = useState(empty);
	const [postCode, setPostCode] = useState(empty);
	const [country, setCountry] = useState(empty);
	const [region, setRegion] = useState(empty);
	const [splist, setSPlist] = useState([]);
	const [spView, setSpView] = useState(false);
	const [spValue, setSpValue] = useState(empty);
	const [spSelect, setSpSelected] = useState(empty);
	const [outletType, setOutletType] = useState(empty);
	const [longitude, setLongitude] = useState(empty);
	const [latitude, setLatitude] = useState(empty);
	const [latlong, setLatlong] = useState(empty);
	const [countries, setCountries] = useState([]);
	const [custCategory, setCustCategory] = useState({});
	const [custChain, setCustChain] = useState({});
	const [custType, setCustType] = useState({});
	const [custArea, setCustArea] = useState({});
	const [custCategoryName, setCustCategoryName] = useState(empty);
	const [custChainName, setCustChainName] = useState(empty);
	const [custTypeName, setCustTypeName] = useState(empty);
	const [custAreaName, setCustAreaName] = useState(empty);
	const [custCategoryId, setCustCategoryId] = useState(0);
	const [custChainId, setCustChainId] = useState(0);
	const [custTyperId, setCustTypeId] = useState(0);
	const [custAreaCode, setCustAreaCode] = useState(empty);
	const [value, setValue] = useState({});
	const [inputValue, setInputValue] = useState('');
	const [nextCustCode, setNextCustCode] = useState('');
	const [custCodeProps, setCustCodeProps] = useState(20);
	const [mobileCheckinID, setMobileCheckinID] = useState(empty);
	const [autoCapsOutletName, setAutoCapsOutletName] = useState(false);
	const [selectedBranch, setSelectedBranch] = useState([]);

	useEffect(() => {
		var axiosGetConfig = axiosDefaultConfig(gState.client);
		setAxiosConfig(axiosGetConfig);
		getSalesmanList(axiosGetConfig);
		getCountry(axiosGetConfig);
		if (salesperson != '') {
			setSpSelected(salesperson);
		}
		if (customer) {
			setCustCode(customer?.cust_code);
			setCustName(customer?.cust_company_name);
			setState(customer?.billing_state);
			setCustAddress1(customer?.billing_address1);
			setCustAddress2(customer?.billing_address2);
			setCity(customer?.billing_city);
			setPostCode(customer?.billing_zipcode);
			setCountry(customer?.billing_country);
			setRegion(customer?.region);
			// setLatitude(customer?.latitude);
			// setLongitude(customer?.longitude);
			setLatlong(customer?.latitude+","+customer?.longitude);
			setCustCategoryName(customer?.cust_category_name);
			setCustCategoryId(customer?.cust_category);
			setCustChainName(customer?.cust_chain_name);
			setCustChainName(customer?.cust_chain);
			setCustAreaCode(customer?.cust_area);

			for (let i = 0; i < custCategoryList.length; i++) {
				const element = custCategoryList[i];
				if(customer?.cust_category == element.cust_category_id){
					setCustCategory(()=>element);
					break;
				}
			}

			for (let j = 0; j < custChainList.length; j++) {
				const element = custChainList[j];
				if(customer?.cust_chain == element.cust_chain_id){
					setCustChain(()=>element);
					break;
				}
			}
			for (let j = 0; j < custTypeList.length; j++) {
				const element = custTypeList[j];
				if(customer?.cust_type == element.id){
					setCustType(()=>element);
					break;
				}
			}
			for (let j = 0; j < custAreaList.length; j++) {
				const element = custAreaList[j];
				if(customer?.cust_area == element.area_code){
					setCustAreaName(element.area_name);
					setCustArea(()=>element);
					break;
				}
			}
			if (customer.company) {
				setSelectedBranch(JSON.parse(customer.company));
			}
		} else {
			if (gState.company) {
				setSelectedBranch(JSON.parse(gState.company));
			}
		}
		// de("betty", custChainList, custTypeList, branchesList);
	}, [open]);

	useEffect(() => {
		if(currentInput){
			setCustName(autoCapsOutletName ? currentInput?.outlet_name.toUpperCase() : currentInput?.outlet_name);
			setCustAddress1(currentInput?.outlet_address);
			setMobileCheckinID(currentInput?.mobile_checkin_id);
			setSpValue(currentInput?.salespersonCode);
			setSpSelected(currentInput?.salespersonName);
		}
	}, [currentInput]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const getCountry = (config = axiosConfig) => {
		API_Service.get(req_module_setting, config).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const country = getSetting(extraData, 'country');
				let country_arr = [JSON.parse(JSON.stringify(country))];
				setCountries(country_arr);

				if(!currentInput){
					const _config = getSetting(extraData, 'customization', {});
					if(_config.custCodeRef){
						setNextCustCode(_config.custCodeRef + _config.custNextRunningNo);
						if(!customer){
							setCustCode(_config.custCodeRef + _config.custNextRunningNo);
						}
					}
					if(_config.customer_code){
						setCustCodeProps(_config.customer_code);
					}
				}

				const _customization = getSetting(extraData, 'customization');
				if (_customization.autoCapsOutletName) {
					setAutoCapsOutletName(true);
				}
			} else {
				snack(language.not_found_salesperson, 'warning');
			}
		});
	}

	const getSalesmanList = (config = axiosConfig) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, config).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, staff_code, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: staff_code, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			} else {
				snack(language.not_found_salesperson, 'warning');
			}
		});
	}

	const closeRemove = (data) => {
		setCustCode(empty);
		setCustName(empty);
		setState(empty);
		setSpSelected(empty);
		setSpValue(empty);
		setCustAddress1(empty);
		setCustAddress2(empty);
		setCity(empty);
		setPostCode(empty);
		setCountry(empty);
		setRegion(empty);
		setLatitude(empty);
		setLongitude(empty);
		// setOutletType(empty);
		// setCustomer(data[0]);
		setCustCategory({});
		setCustChain({});
		setCustCategoryName(empty);
		setCustChainName(empty);
		setCustCategoryId(0);
		setCustChainId(0);
		setCustAreaCode(empty);
		setCustAreaName(empty);
		setCustArea({});
		onClose(data);
	}

	const addNewCustomer = () => {
		let formData = new FormData();
		formData.append('cust_code', custCode);
		formData.append('cust_company_name', custName);
		formData.append('billing_address1', custAddress1);
		formData.append('billing_address2', custAddress2);
		formData.append('billing_state', state);
		formData.append('billing_city', city);
		formData.append('billing_zipcode', postCode);
		formData.append('billing_country', country);
		formData.append('salesperson', spValue);
		const latlongArray = latlong.split(',');
		formData.append('longitude', latlongArray[1]);
		formData.append('latitude', latlongArray[0]);
		// formData.append('latlong', latlong);
		formData.append('region', region);
		formData.append('cust_category', custCategoryId);
		formData.append('cust_chain', custChainId);
		formData.append('cust_type', custTyperId);
		formData.append('cust_area', custAreaCode);
		formData.append('company', selectedBranch);
		// formData.append('type',outletType);

		if(currentInput){
			formData.append('checkin_id', mobileCheckinID);
			if (custName === empty || custAddress1 === empty) {
				snack('Insert Outlet Name and Address', 'warning');
			} else {
				API_Service.post(req_cust_approve, formData, axiosConfig).then(({data}) => {
					const {result, message, extraData} = data;
					if (result) {
						snack(gState.translation.insert_success);
						closeRemove(extraData);
					} else {
						snack(message, 'warning');
					}
				});
			}
		}else{
			if (custName === empty || custAddress1 === empty) {
				snack('Insert Outlet Name and Address', 'warning');
			} else {
				API_Service.post(req_cust_insert, formData, axiosConfig).then(({data}) => {
					const {result, message, extraData} = data;
					if (result) {
						snack(gState.translation.insert_success);
						closeRemove(extraData);
					} else {
						snack(message, 'warning');
					}
				});
			}
		}
	}

	const editCustomer = () => {
		const latlongArray = latlong.split(',');
		const params = axiosURIencode({
			ori_cust_code: customer?.cust_code,
			cust_code: custCode,
			cust_company_name: custName,
			billing_address1: custAddress1,
			billing_address2: custAddress2,
			billing_state: state,
			billing_city: city,
			billing_zipcode: postCode,
			billing_country: country,
			salesperson: spValue,
			longitude: latlongArray[1],
			latitude: latlongArray[0],
			// latlong: latlong,
			region: region,
			cust_category: custCategoryId,
			cust_chain: custChainId,
			cust_type: custTyperId,
			cust_area: custAreaCode,
			company: selectedBranch,
		});

		if (custCode === empty || custName === empty || custAddress1 === empty) {
			snack('Insert Outlet Code, Outlet Name and Address', 'warning');
		} else {
			API_Service.put(req_cust_insert, params, axiosConfig).then(({data}) => {
				const {result, extraData} = data;
				if (result) {
					snack(gState.translation.insert_success);
					closeRemove(extraData)
				} else {
					snack(data.message, 'warning');
				}
			});
		}
	}

	const spViewClick = () => {
		setSpView(true);
	}

	const spViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			var spValue = '';
			var spName = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spValue += newValue[i].value;
					spName += newValue[i].name;
				} else {
					spValue += newValue[i].value + ",";
					spName += newValue[i].name + ",";
				}
			}
			setSpValue(spValue);
			setSpSelected(spName);
		} else {
			setSpValue(empty);
			setSpSelected(empty);
		}
	}

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Dialog fullScreen={fullScreen} onClose={closeRemove} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">
				{customer ? language.edit_customer : language.add_customer}
			</DialogTitle>
			<div style={{width: 600, padding: theme.spacing(2)}}>
				<div className={classes.viewInRowSpace}>
					<TextField
						id="input-cust-name"
						style={{width: '48%', height: 50}}
						inputProps={{maxLength: custCodeProps, pattern: "[a-z]{1,15}"}}
						label={language.cust_code}
						value={custCode == empty ? nextCustCode : custCode}
						// disabled={currentCustomer != null ? true : false}
						onChange={(e) => setCustCode(e.target.value)}
					/>
					<TextField
						id="input-cust-name"
						style={{width: '48%', height: 50}}
						label={language.outlet_name}
						value={custName}
						onChange={(e) => setCustName(autoCapsOutletName ? e.target.value.toUpperCase() : e.target.value)}
						inputProps={{ style: { textTransform: autoCapsOutletName ? "uppercase" : "none" } }}
					/>
					<TextField
						id="input-cust-address1"
						style={{width: '48%', height: 50}}
						label={language.address1}
						value={custAddress1}
						onChange={(e) => setCustAddress1(e.target.value)}
					/>
					<TextField
						id="input-cust-address2"
						style={{width: '48%', height: 50}}
						label={language.address2}
						value={custAddress2}
						onChange={(e) => setCustAddress2(e.target.value)}
					/>
					<FormControl style={{width: '48%', height: 50}}>
						<InputLabel id="demo-simple-select-label">{language.country}</InputLabel>
						<Select
							value={country}
							onChange={(e) => setCountry(e.target.value)}
						>
							{countries.map((country, idx) => (
								<MenuItem
									value={country.countryName}
									key={country.countryShortCode+idx+country.countryName}
								>
									{country.countryName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl style={{width: '48%', height: 50}}>
						<InputLabel id="demo-simple-select-label">{language.state}</InputLabel>
						<Select
							value={state}
							onChange={(e) => setState(e.target.value)}
							disabled={!country}
						>
							{country
								? countries
									?.find(({countryName}) => countryName === country)
									?.states.map((states) => (
										<MenuItem value={states.name}>
											{states.name}
										</MenuItem>
									))
								: []}
						</Select>
					</FormControl>
					<FormControl style={{width: '48%', height: 50}}>
						<InputLabel id="demo-simple-select-label">{language.region}</InputLabel>
						<Select
							value={region}
							onChange={(e) => setRegion(e.target.value)}
							disabled={!country}
						>
							{country
								? countries
									?.find(({countryName}) => countryName === country)
									?.regions.map((regions) => (
										<MenuItem value={regions.name}>
											{regions.name}
										</MenuItem>
									))
								: []}
						</Select>
					</FormControl>
					<TextField
						id="input-cust-city"
						style={{width: '48%', height: 50}}
						label={language.city}
						value={city}
						onChange={(e) => setCity(e.target.value)}
					/>
					<TextField
						id="input-cust-postcode"
						style={{width: '48%', height: 50}}
						InputProps={{
							inputComponent: NumberFormatCustom,
						}}
						label={language.zipcode}
						value={postCode}
						onChange={(e) => setPostCode(e.target.value)}
					/>
					{
						branchesList.length > 0 && (
							<FormControl style={{width: '48%', height: 50}}>
								<InputLabel id="demo-simple-select-label">Branch</InputLabel>
								<Select
									disabled={gState.company}
									value={selectedBranch}
									onChange={(e) => {
										setSelectedBranch(e.target.value);
										// setEdited(true);
									}}
									multiple
								>
									{branchesList.map((data) => (
										<MenuItem
											value={data.value}
											key={data.value}
										>
											{data.name.toUpperCase()}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)
					}
					<div style={{width: '48%'}}/>
					<TextField
						id="input-cust-latitude-longitude"
						style={{width: '100%', height: 50}}
						// InputProps={{
						// 	inputComponent: NumberFormatCustom,
						// }}
						label={language.latitude +','+ language.longitude}
						value={latlong}
						onChange={(e) => setLatlong(e.target.value)}
					/>
				</div>
				<div style={{height: 20}}/>
				<Autocomplete
					autoHighlight
					className={classes.btn}
					options={custAreaList || []}
					onChange={(event, newValue) => {
						if (!_.isEmpty(newValue)) {
							setCustAreaName(newValue['name']);
							setCustArea(newValue);
							setCustAreaCode(newValue['area_code']);
						}
					}}
					value={custArea}
					inputValue={custAreaName}
					onInputChange={(event, newInputValue) => {
						setCustAreaName(newInputValue);
					}}
					noOptionsText={language.no_data}
					getOptionLabel={(option) => option.area_name || ""}
					renderInput={(params) => <TextField {...params} label="Customer Area" variant="outlined"/>}
				/>
				<div style={{height: 20}}/>
				<Autocomplete
					autoHighlight
					className={classes.btn}
					options={custCategoryList || []}
					onChange={(event, newValue) => {
						if (!_.isEmpty(newValue)) {
							setCustCategory(newValue);
							setCustCategoryName(newValue['cust_category_name']);
							setCustCategoryId(newValue['cust_category_id']);
						}
					}}
					onInputChange={(event, newInputValue) => {
						setCustCategoryName(newInputValue);
					}}
					value={custCategory}
					inputValue={custCategoryName}
					//noOptionsText={language.no_data}
					getOptionLabel={(option) => option.cust_category_name || ""}
					renderInput={(params) => <TextField {...params} label="Customer Category" variant="outlined"/>}
				/>
				<div style={{height: 20}}/>
				<Autocomplete
					autoHighlight
					className={classes.btn}
					options={custChainList || []}
					onChange={(event, newValue) => {
						if (!_.isEmpty(newValue)) {
							setCustChainName(newValue['cust_chain_name']);
							setCustChain(newValue);
							setCustChainId(newValue['cust_chain_id']);
						}
					}}
					value={custChain}
					inputValue={custChainName}
					onInputChange={(event, newInputValue) => {
						setCustChainName(newInputValue);
					}}
					noOptionsText={language.no_data}
					getOptionLabel={(option) => option.cust_chain_name || ""}
					renderInput={(params) => <TextField {...params} label="Customer Chain" variant="outlined"/>}
				/>
				<div style={{height: 20}}/>
				<Autocomplete
					autoHighlight
					className={classes.btn}
					options={custTypeList || []}
					onChange={(event, newValue) => {
						if (!_.isEmpty(newValue)) {
							setCustTypeName(newValue['name']);
							setCustType(newValue);
							setCustTypeId(newValue['id']);
						}
					}}
					value={custType}
					inputValue={custTypeName}
					onInputChange={(event, newInputValue) => {
						setCustTypeName(newInputValue);
					}}
					noOptionsText={language.no_data}
					getOptionLabel={(option) => option.name || ""}
					renderInput={(params) => <TextField {...params} label="Customer Type" variant="outlined"/>}
				/>
				<div style={{height: 20}}/>
				<Button
					size="small"
					variant="outlined"
					onClick={() => spViewClick()}
					style={{width: '100%', height: 50}}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography component="p" className={commonStyle.searchBtnFont}>
						{spSelect ? _.truncate(spSelect, {length: 20}) : language.salesman}
					</Typography>
				</Button>
				<div style={{height: 20}}/>
				<div style={{display: "flex"}}>
					{
						typeof onCancel === 'function' && (
							<Button
								className={classes.error}
								style={{marginLeft: 'auto'}}
								variant="outlined"
								onClick={() => {
									onCancel()
								}}
								color={'primary'}>
								{language.cancel}
							</Button>
						)
					}
					<Button
						style={{marginLeft: typeof onCancel === 'function' ? 10 : 'auto'}}
						disabled={spSelect.value != 0 ? false : true}
						variant="outlined"
						onClick={() => {
							customer ? editCustomer() : addNewCustomer()
						}}
						color={'primary'}>
						{customer ? language.edit : language.add}
					</Button>
				</div>
				<div style={{height: 20}}/>
			</div>
			<VirtualListCB
				open={spView}
				setClose={data => spViewClose(data)}
				title={language.salesman}
				data={splist}
				language={language}
				filteration={0}
				enabledCheckAll={false}
			/>
		</Dialog>
	)
}

function CurrencyFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={true}
			isNumericString
			prefix={'RM'}
			min={0}
			max={999999}
		/>
	);
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={false}
			isNumericString
			min={0}
			max={999999}
		/>
	);
}
